@import "src/assets/css/variables";

.veranstaltungsliste-card-container {
  display: flex;
  flex-wrap: wrap;
}

.veranstaltungsliste-card {
  width: 13rem;
  min-height: 13rem;

  margin: 1rem;

  box-shadow: 0 .25rem 1rem $color_shadow;

  // Der Link umfasst die ganze Card um die Veranstaltung einfacher auswählbar
  // zu machen, der Text soll aber die Standard-Farbe behalten.
  color: $color_text;

  transition: all .2s;

  &:hover {
    transform: scale(1.025);
  }
}

.veranstaltungsliste-name {
  font-family: Tomorrow, sans-serif;
  color: $color_primary;
}

.veranstaltungsliste-ueberschrift {
  color: $color_infotext;
}
