@import "src/assets/css/variables";
@import "src/assets/css/mixins";

.app-info-container {
  font-family: Tomorrow, sans-serif;
}

.app-info-name {
  font-size: 1.8rem;

  margin: 0;

  a {
    @include forAllAnchorPseudoClasses {
      color: $color_secondary;
    }
  }
}

.app-info-version {
  font-size: .6rem;

  color: $color_infotext;
}
