@import "variables";
@import "src/assets/css/mixins";

// Custom Font - Tomorrow
@font-face {
  font-family: 'Tomorrow';
  font-style: normal;
  font-weight: 500;
  src: local('Tomorrow Medium'),
  local('Tomorrow-Medium'),
  url('../fonts/Tomorrow-Medium.woff2') format('woff2'),
  url('../fonts/Tomorrow-Medium.woff') format('woff');
}

// CSS Framework - Spectre
// + override theme colors
$primary-color: $color_primary;
$secondary-color: $color_secondary;
@import "node_modules/spectre.css/src/spectre";
@import "node_modules/spectre.css/src/spectre-exp";

// Line Awesome Icons
@import "node_modules/line-awesome/dist/line-awesome/scss/line-awesome";

// Typography
a {
  @include forAllAnchorPseudoClasses {
    color: $color_primary;
    text-decoration: none;
  }
}
