.off-canvas-layout {
  // Stellt sicher, dass die eingefärbte Sidebar immer bis nach ganz unten reicht.
  min-height: 100vh;
}

.off-canvas-layout-sidebar {
  width: 14rem;
}

.off-canvas-layout-content {
  // Padding-Left von 4 auf 3rem reduzieren, um dem Content mehr Platz zu geben.
  padding: 0.4rem 0.4rem 0.4rem 3rem !important;

  // Sticky Footer am unteren Seitenrand verankern.
  min-height: 100vh;
  display: flex;
  flex-direction: column;

  .off-canvas-layout-header, .off-canvas-layout-footer {
    flex-shrink: 0;
  }

  .off-canvas-layout-main {
    flex-grow: 1;
  }
}

// Für den Druck irrelevante Teile ausblenden.
@media print {
  .off-canvas-layout-toggle {
    display: none !important;
  }
  .off-canvas-layout-sidebar {
    display: none !important;
  }
}
