$color_dkv_blue: #293D9B;
$color_dkv_red: #D42D21;

$color_primary: $color_dkv_blue;
$color_secondary: $color_dkv_red;
$color_shadow: #333333;
$color_text: #424242;
$color_infotext: #888888;

// Grenze bei welcher das Mobile- oder Desktop-Layout verwendet wird.
$mobile-breakpoint: 1050px;
