@import "src/assets/css/variables";

.rennergebnisse-table {

  font-size: .7rem;

  .rennergebnisse-verein, .rennergebnisse-abstand {
    color: $color_infotext;
  }

  tbody tr:hover {
    color: $color_secondary;

    .rennergebnisse-verein, .rennergebnisse-abstand {
      color: $color_secondary;
    }
  }

  tbody tr td {

    font-family: monospace;
    padding: 0;

    &:nth-child(1) {
      // Spalte "Platzierung" hervorheben.
      font-weight: bold;
      text-align: center;
    }

    @media screen and (min-width: $mobile-breakpoint) {
      &:nth-child(3) {
        // Spalte "Sportler / Verein" in der Breite immer begrenzen, um den formatieren Zeiten mehr Platz zu geben.
        max-width: 8rem;
        overflow-wrap: break-word;
      }
    }
  }

  // Breite der Tabelle auf sehr großen Screens begrenzen.
  @media screen and (min-width: 1600px) {
    width: 1250px !important;
  }

  // Auf kleinen bis mittleren Bildschirmen wird eine abgekürzte Variante der Tabellen Kopfzeile angezeigt.
  @media screen and (max-width: 1350px) {
    th span {
      display: none;
    }
    th::after {
      content: attr(data-abkuerzung);
    }
  }

  // In der mobilen Ansicht werden die Ergebnisse untereinander und nicht mehr tabellarisch dargestellt.
  @media screen and (max-width: $mobile-breakpoint) {

    tr {
      display: block;
      margin-bottom: 1rem;
      margin-right: 1rem;
      box-shadow: 0 .25rem 1rem $color_shadow;
      padding: .5rem;
    }

    td {
      display: block;
      margin-bottom: .5rem;
      font-family: inherit !important;
      border-bottom: none !important;
      min-height: 1em; // wichtig wenn eine Zeile (z.B. die Platzierung) keinen Inhalt hat
    }

    thead {
      display: none;
    }

    td::before {
      content: attr(data-mobile-label);
      float: left;
      margin-right: .25rem;
    }

    // Spalte "Platzierung" zentrieren.
    td:nth-child(1)::before {
      float: unset;
    }

    // Vertical Alignment für Gesamtzeit und Abstand
    td:nth-child(6) pre {
      display: table;
    }
  }
}
